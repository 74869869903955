a.page-link {
  font-size: 1.1em;
}

a.page-link:before {
  content: "● ";
  color: $green-color;
}

a.page-link:hover:before {
  color: $blue-color;
}

address {
  margin-bottom: 1em;
}

hr {
  background-color: rgba(0,0,0,0.13);
  color: rgba(0,0,0,0.13);
  height: 1px;
  border: 0;
  margin-bottom: 1em;
}

.site-footer {
  padding-top: 1em;
}

h4 {
  margin-bottom: 0;
}

@media (min-width: 800px) {
  div.three-columns {
    display: flex;

    div {
      width: 33%;
    }
  }
}

div.trigger {
  text-align: left;

  a.page-link {
    margin-left: 0;
  }
}

p {
  text-align: justify;
}

header {
  padding-bottom: 1em;
}
